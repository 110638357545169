import { render, staticRenderFns } from "./default.vue?vue&type=template&id=02878ecd&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=02878ecd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/Footer/index.vue').default,BackTop: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/BackTop/index.vue').default})
