import { render, staticRenderFns } from "./base.vue?vue&type=template&id=6d030c68&scoped=true&"
import script from "./base.vue?vue&type=script&lang=js&"
export * from "./base.vue?vue&type=script&lang=js&"
import style0 from "./base.vue?vue&type=style&index=0&id=6d030c68&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d030c68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBarHorizontal: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/NavBar/horizontal.vue').default,Loading: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/Loading/index.vue').default,Footer: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/Footer/index.vue').default,BackTop: require('C:/Users/Administrator/Desktop/workNuxt/mecclePc/components/BackTop/index.vue').default})
