export default ({ app }) => {
  if (process.client) {  // Facebook
  const facebookScript = document.createElement('script');
  facebookScript.textContent = `
    setTimeout(function() {
     !function(f,b,e,v,n,t,s) {
        if(f.fbq)return;
        n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue=[];
        t=b.createElement(e);
        t.async=!0;
        t.src=v;
        s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s);
      }(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '832685061480710');
      fbq('track', 'PageView');
    }, 2000);
  `;
  document.head.appendChild(facebookScript);

  // Facebook noscript
  const facebookNoscript = document.createElement('noscript');
  const facebookImg = document.createElement('img');
  facebookImg.height = '1';
  facebookImg.width = '1';
  facebookImg.style.display = 'none';
  facebookImg.src = 'https://www.facebook.com/tr?id=832685061480710&ev=PageView&noscript=1';
  facebookNoscript.appendChild(facebookImg);
  document.head.appendChild(facebookNoscript);

  // Google Analytics
  const googleAnalyticsScript = document.createElement('script');
  googleAnalyticsScript.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-H2YDVZBCWQ');
  `;
  document.head.appendChild(googleAnalyticsScript);

  // Google Tag Manager
  const gtmScript1 = document.createElement('script');
  gtmScript1.async = true;
  gtmScript1.src = 'https://www.googletagmanager.com/gtag/js?id=G-H2YDVZBCWQ';
  document.head.appendChild(gtmScript1);

  const gtmScript2 = document.createElement('script');
  gtmScript2.textContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K239KXSH');
  `;
  document.head.appendChild(gtmScript2);

  // Another Google Analytics
  const anotherGoogleAnalyticsScript = document.createElement('script');
  anotherGoogleAnalyticsScript.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-11328215896');
  `;
  document.head.appendChild(anotherGoogleAnalyticsScript);

  // Google Tag Manager for another ID
  const gtmScript3 = document.createElement('script');
  gtmScript3.async = true;
  gtmScript3.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11328215896';
  document.head.appendChild(gtmScript3);

  // TikTok
  const tiktokScript = document.createElement('script');
  tiktokScript.textContent = `
   !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
      ttq.load('CO93VEBC77U91879A600');
      ttq.page();
    }(window, document, 'ttq');
  `;
  document.head.appendChild(tiktokScript);
}
};