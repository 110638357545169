
export default {
  props: {
    animaBol: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      animateImage1: false,
      animateImage2: false
    };
  },
  watch: {
    animaBol: {
      handler (newV, oldV) {
        let _this = this
        if (newV) {
          setTimeout(() => {
            _this.startAnimation()
          }, 500);
        }
      },
      immediate: true
    },
  },

  created () {


  },
  methods: {
    startAnimation () {
      // 设置动画开始
      this.animateImage1 = true;

      // 设置第二张图片显示
      setTimeout(() => {
        this.animateImage2 = true;
      }, 2000); // 等待第一张图片动画完成后显示第二张图片
    }
  }
};
