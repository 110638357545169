const state = () => {
    return {
      // 测试信息
      address_id:{},
        order_ids:[], // 订单id
        addressCode:{}
    }
  }

  const mutations ={
       setAddress(state,params){
        state.address_id = params
       },
       setOutTrade(state,params){
        state.order_ids = params
       },
       setaddressCode(state,params){
        state.addressCode = params
       }
    }
    const actions= {
       SETADDRESS({commit},params){
        commit('setAddress', params)
       },
       SETOUTTRADE({commit},params){
        commit('setOutTrade', params)
       },
       SETADDRESSCODE({
        commit
       }, params) {
        this.$storage.setSessionStorage('setaddressCode', params)
        commit('setaddressCode', params)
    },
}

export default {
    namespaced : true,
    state,
    actions,
    mutations
  }
// export default address;