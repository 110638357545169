

const state = () => {
    return {
      // 测试信息
      active:0, //历史记录tab切换
      cartList: [],
      orderInfo: {},
      setType: '',
      carObj: {},
      userInfo: {},
      tabList:[],
      historyList: {
          goods_history: []
      },
      classifyStr: "",
      newsubmit:{}
    }
  }

  const mutations =  {
        newsubmit(state, params){
            state.newsubmit = params  
        },
        setCartList(state, params) {
            state.cartList = params
        },
        ceateOrder(state, params) {
            state.orderInfo = params
        },
        setType(state, params) {
            state.setType = params
        },
        setTab(state, params) {
            state.active = params
        },
        setHistoryList(state, params) {
            state.historyList = params
        },
        setCartobj(state, params) {
            state.carObj = params
        },
        setUserInfo(state, params) {
            state.userInfo = params
        },
        setTablist(state, params) {
            state.tabList = params
            // state.tabList = []
        },
        setclassify(state, params) {
            state.classifyStr = params
        },

    }
    const actions= {
        SETCARTLIST({
            commit
        }, params) {
            this.$storage.setSessionStorage('cartList', params)
            commit('setCartList', params)
        },

        CEATEORDER({
            commit
        }, params) {
            debugger
            this.$storage.setSessionStorage('ceateOrder', params)
            commit('ceateOrder', params)
        },
        SETTYPE({
            commit
        }, params) {
            this.$storage.setSessionStorage('setType', params)
            commit('setType', params)
        },
        SETTAB({
            commit
        }, params) {
            this.$storage.setSessionStorage('setTab', params)
            commit('setTab', params)
        },
        SETCAROBJ({
            commit
        }, params) {
            this.$storage.setSessionStorage('setCartobj', params)
            commit('setCartobj', params)
        },
        SETUSERINFO({
            commit
        }, params) {
            this.$storage.setSessionStorage('setUserInfo', params)
            commit('setUserInfo', params)
        },
        SETTABLIST({
            commit
        }, params) {
            this.$storage.setSessionStorage('setTablist', params)
            commit('setTablist', params)
        },
        
        SETHISTORYLIST({
            commit
        }, params) {
            this.$storage.setSessionStorage('historyList', params)
            commit('setHistoryList', params)
        },
        SETCLASSIFY({
            commit
        }, params) {
            this.$storage.setSessionStorage('setclassify', params)
            commit('setclassify', params)
        },
        NEWSUBMIT({
            commit
        }, params) {
            this.$storage.setSessionStorage('newsubmit', params)
            commit('newsubmit', params)
        }
    }

export default {
    namespaced : true,
    state,
    actions,
    mutations
  }