
export default {
  async asyncData ({ error, redirect }) {
    console.log(error, 9000088)
    if (error.statusCode === 404) {
      // 如果是 404 错误，重定向到首页
      redirect('/');
    }
  },
  data () {
    return {}
  },
  head () {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: ''

        }
      ]
    }
  }
}
