
export default {
  props: {
    last_time: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      firstVisitTime: 0, // 从本地存储加载第一次访问时间
      timer: undefined, //定时器
      result: "", //倒计时显示
      h: "",
      m: "",
      s: "",
      taTime: true
    }
  },
  watch: {
    last_time: {
      handler (newV, oldV) {
        this.firstVisitTime = newV
        if (newV > 0) {
          this.startTime() //调用定时器
        }
        //
      },
      immediate: true
    }
  },
  created () {

  },
  mounted () {

  },
  beforeDestroy () {
    //页面销毁清除定时器
    clearInterval(this.timer)
  },
  methods: {
    loadFirstVisitTime () {
      const storedTime = this.$storage.getLocalStorage('firstVisitTime');
      if (storedTime) {
        return parseInt(storedTime);
      } else {
        const currentTime = new Date().getTime();
        this.$storage.setLocalStorage('firstVisitTime', currentTime.toString());
        return currentTime;
      }
    },
    //获取当前时间的24小时倒计时
    countDown (countdownTime) {
      // let now = new Date();         // 获取当前时间
      // const countdownTime = countTime - (now - orderTime) //剩余的时间，越来越少
      // console.log(countdownTime)
      // const countdownTime = this.last_time
      let h = parseInt(countdownTime / 60 / 60 % 24)
      let m = parseInt(countdownTime / 60 % 60)
      let s = Math.floor(countdownTime % 60)

      this.h = h > 9 ? h : '0' + h;
      this.m = m > 9 ? m : '0' + m;
      this.s = s > 9 ? s : '0' + s;
      this.result = h + '时' + m + '分' + s + '秒';
      if (countdownTime == 0) {
        let data = {
          index: this.index,
          timeNum: 0
        }
        this.$emit('updateindex', data)
        this.taTime = false
        clearInterval(this.timer)
        return
        // const currentTime = new Date().getTime();
        // this.$storage.setLocalStorage('firstVisitTime', currentTime.toString());
      }
      return this.result;

    },
    startTime () {
      // let order_time = new Date(); //订单时间
      // let order_time = this.firstVisitTime + 24 * 60 * 60 * 1000
      // let count_time = 24 * 60 * 60 * 1000 //倒计时24小时毫秒数;
      let order_time = this.firstVisitTime + 24 * 60 * 60 * 1000
      let count_time = 24 * 60 * 60 * 1000 //倒计时24小时毫秒数;
      // 循环执行
      var _this = this
      if (_this.taTime) {
        _this.timer = setInterval(() => {
          if (_this.firstVisitTime > 0) {
            _this.firstVisitTime -= 1
          }

          _this.countDown(_this.firstVisitTime)
        }, 1000)
      }

    },
  }
}
