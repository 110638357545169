
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure( { showSpinner : false } )

// import http from '@/utils/request'
import cookies from '@/utils/cookie'
import CONFIGS from '../config'
const whiteList = [
  '/login', '/', '/hardware/jimo', '/hardware/actionone', '/hardware/jimo2'
]

const isClient = process.client

// // minimum 设置开始时最低百分比，默认是0.08
// NProgress.configure( { minimum : 0.1 } )
// // template改变进度条的HTML结构。为了保证进度条正常工作，需要一个包含role='bar' 属性的元素
// NProgress.configure( {
//   template : "<div class='....'>...</div>"
// } )
// // ease和speed ease可传递CSS3缓冲动画字符串（如ease、linear、ease-in、ease-out、ease-in-out、cubic-bezier）。speed为动画速度（默认200，单位ms）
// NProgress.configure( { easing : 'ease', speed : 500 } )
// // trickle 是否显示进度条，默认：true
// NProgress.configure( { trickle : false } )
// // trickleSpeed设置每次进度条步进速度(ms)
// NProgress.configure( { trickleSpeed : 200 } )
// // showSpinner是否显示环形进度动画，默认true
// NProgress.configure( { showSpinner : false } )
// // parent 指定改变的父容器，默认body
// NProgress.configure( { parent : '#container' } )
function convertString(str) {
  const parts = str.split('-');
  const id = parts.pop();
  const productName = parts.join('-');
  return `${productName}?id=${id}`;
}
function activeString(str) {
  const parts = str.split('-');
  const id = parts.pop();
  const productName = parts.join('-');
  return `${productName}?active=${id}`;
}
export default ( { app, store } ) => {
  app.router.beforeEach( async( to, from, next ) => {
    if ( isClient ) {
      // if (window.location.hostname=="www.meccle.com"){
      //   const mobileDomain = 'http://m.meccle.com';
      //   const productPathRegex = /^\/products\//;//判断是否存在products
      //   const categoriesPathRegex = /^\/categories\//;//判断是否存在categories
      //   const currentPath = window.location.pathname;
      //   if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //   } else {
      //     if(productPathRegex.test(currentPath)){
      //       const parts = currentPath.split('/products/');
      //       const productContent = parts[1];//获取products后的内容
      //       var newString = convertString(productContent);
      //       window.location.href = mobileDomain+'/products/'+newString;
      //     } else if(categoriesPathRegex.test(currentPath)){
      //       const partscategories = currentPath.split('/categories/');
      //       const categoriesContent = partscategories[1];//获取products后的内容
      //       var acvString = activeString(categoriesContent);
      //       window.location.href = mobileDomain+'/categories/'+acvString;
      //     }else{
      //       window.location.href = mobileDomain
      //     }
      //   }
      // }
    }
    next() 
    // isClient && NProgress.done()
  } )

  app.router.afterEach( () => {
    // isClient && NProgress.done()
  } )
}
