// plugins/storage.js
export default ({ app }, inject) => {
  if (process.client) {
    // 注入到 Vue 实例中
    inject('storage', {
      setLocalStorage(key, value) {
        const d = JSON.stringify(value);
        localStorage.setItem(key, d);
      },
      getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key));
      },
      setSessionStorage(key, value) {
        const d = JSON.stringify(value);
        sessionStorage.setItem(key, d);
        // sessionStorage.setItem(key, value);
      },
      getSessionStorage(key) {
        return JSON.parse(sessionStorage.getItem(key));
        // return sessionStorage.getItem(key);
      },
      clearLocalStorage(key){
        localStorage.removeItem(key);
      },
      clearSessionStorage(key){
        sessionStorage.removeItem(key);
      },
    });
  }
}
