export default ( { app, store } ) => {
  const detectDeviceType = () => {
      // 通过检测屏幕宽度来大致判断设备类型，这里以1024px为界，可根据实际情况调整
      const screenWidth = window.innerWidth;
      if (screenWidth >= 900) {
          return 'desktop';
      } else {
          return 'mobile';
      }
  };
  function convertString(str) {
    const parts = str.split('-');
    const id = parts.pop();
    const productName = parts.join('-');
    return `${productName}?id=${id}`;
  }

  const redirectToDomain = () => {
    if (window.location.hostname=="www.meccle.com"){
      const deviceType = detectDeviceType();
      const pcDomain = 'http://www.meccle.com';
      const mobileDomain = 'http://m.meccle.com';
      const productPathRegex = /^\/products\//;//判断是否存在products
      const currentPath = window.location.pathname;
      if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          // window.location.href = pcDomain;
      } else {
        // let newString = originalString.replace('?id=', '-');
        if(productPathRegex.test(currentPath)){
          const parts = currentPath.split('/products/');
          const productContent = parts[1];//获取products后的内容
          var newString = convertString(productContent);
          window.location.href = mobileDomain+'/products/'+newString;
        }else{
          window.location.href = mobileDomain
        }
         
      }
    }
  };

  // 在页面加载完成后执行重定向操作
  window.onload = redirectToDomain;
};