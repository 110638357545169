 const state = () => {
        return {
          // 测试信息
          userInfo: {},
          token: "",
          sactive:"",
          couponInfo:{}
        }
      }
    const mutations ={
        setuserInfo(state, params) {
            state.userInfo = params
        },
        setsactive(state, params) {
            state.sactive = params
        },
        setcouponInfo(state, params) {
            state.couponInfo = params
        },
        // setToken(state, params) {
        //     state.token = params
        // },

    }
    const actions= {
        SETLOGIN({
            commit
        }, params) {
            this.$storage.setSessionStorage('userInfo', params)
            commit('setuserInfo', params)
        },
        SETACTIVE({
            commit
        }, params) {
            this.$storage.setSessionStorage('sactive', params)
            commit('setsactive', params)
        },
        SETCOUPONINFO({
            commit
        }, params) {
            this.$storage.setSessionStorage('couponInfo', params)
            commit('setcouponInfo', params)
        },
        // SETTOKEN({
        //     commit
        // }, params) {
        //     storage.setSessionStorage('token', params)
        //     commit('setToken', params)
        // },
    }
    export default {
        namespaced : true,
        state,
        actions,
        mutations
      }
      