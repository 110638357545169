
export default {
  name : 'Loading',
  mounted() {
  },
  methods : {
    hideLoading() {

    }
  }
}
