
// import { auyFun, sell } from '~static/api'
export default {
  name: 'footer-block',
  props: {},
  data () {
    return {
      QRcode: [],
      bottomInfo: [
        {
          name: "",
          list: [
            {
              name: "About Meccle",
              path: '/AboutUs'
            },
            {
              name: "Contact us",
              path: '/contactUs'
            },

            {
              name: "Download App",
              path: ""
            },
            {
              name: "Sellers hub",
              path: "/sellerCentral"
            },
            {
              name: "Meccle membership",
              path: "/FactoryMember"
            },

            // {
            //   name: 'Get to Know Us',
            //   path: '/AboutUs'
            // },

          ]
        },
        {
          name: "",
          list: [
            {
              name: 'My orders',
              path: '/order'
            },
            {
              name: 'My account',
              path: '/account'
            },
            {
              name: 'Privacy policy'
              // path: '/introduce'
            },
            { name: 'Terms and conditions' },
            { name: 'Returns/ refund policy' },
            // {
            //   name: 'Customer service',
            //   path: ''
            // },
          ]
        },

        {
          name: "Download",
          list: [
            // { name: 'Help Center' },
            // {
            //   name: 'My Account',
            //   path: '/account'
            // },
            // {
            //   name: 'My Order',
            //   path: '/order'
            // }
            // { name: 'Return & Replacement' }
          ]
        },
        {
          name: "",
          list: [
            { name: 'Meccle is a wholly-owned subsidiary of Australian public company Haodex Ltd (ACN 623392325), a National Stock Exchange of Australia listed entity [NSX: HAO].' },
          ]
        },
      ]
    }
  },
  computed: {},
  created () {
    // this.GETCOFIGAB()
    // this.GETCONFIGList()
  },
  mounted () { },
  watch: {},
  methods: {
    async GETCOFIGAB () {
      var self = this
      let orderInfo = {
        key: 'THEME_COLOR'
      }
      let { data, code, msg } = await auyFun.GETCOFIGAB(orderInfo)
      if (code == 200) {
        window.localStorage.setItem('zdiBj', data.content)
        window.localStorage.setItem('zdiBj1', data.value)
        self.zdiBj = data.content
        self.zdiBj1 = data.value
      } else {
      }
    },
    async GETCONFIGList () {
      let orderInfo = {
        key: 'ANDROID_QR_CODE,IOS_QR_CODE'
      }
      let { data, code, msg } = await auyFun.GETCONFIG(orderInfo)
      if (code == 200) {
        this.QRcode = data
      }
    },
    toLLINK (k) {
      if (k == "In association with Haodex Ltd, National Stock ") {
        window.open('https://www.nsx.com.au/summary/HAO', '_blank')
      }
    },
    tolink (path, name) {
      if (name == 'Download App') {
        //新页面打开详情
        window.open('https://download.meccle.com/', '_blank')
      } else if (name == "Privacy policy") {
        this.top1()

      } else if (name == "Returns/ refund policy") {
        this.top4()
      } else if (name == "Terms and conditions") {
        this.top2()
      } else if (name == "Exchange of Australia Listed Entity [NSX: HAO]") {
        window.open('https://www.nsx.com.au/summary/HAO', '_blank')
      } else if (name == "Customer service") {
        window.open('https://meccle.udesk.cn/im_client/?web_plugin_id=189951&agent_id=89431&group_id=83740&language=en-us', '_blank')
      } else {
        this.$customJump("/content" + path)
      }
      // this.$customJump(path)
    },
    todows (kk) {
      if (kk == "ios") {
        window.open('https://apps.apple.com/cn/app/bulkbuyworld/id1624648796', '_blank')
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.bulkbuyworld.app.psj.psjstore', '_blank')
      }
    },
    top2 () {
      var routeData = this.$router.resolve({
        path: '/termsAndConditions',
      })
      window.open(routeData.href, '_blank')
      // termsAndConditions
      // window.open('https://www.meccle.com/terms-and-conditions.html', '_blank')
      // 
    },
    top1 () {
      var routeData = this.$router.resolve({
        path: '/Privacy-policy',
      })
      window.open(routeData.href, '_blank')
      //  window.open('https://www.meccle.com/privacy-policy.html', '_blank')
    },
    top4 () {
      var routeData = this.$router.resolve({
        path: '/refund-and-returns-policy',
      })
      window.open(routeData.href, '_blank')
    }
  },
  components: {}
}
