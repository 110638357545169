import axios from 'axios';
import qs from 'qs'
import Vue from "vue";

class sell {
    constructor() {
        this.getCountry = '/pc/getCountry'; //获取国家列表
        this.stepOne = "/pc/store/stepOne" //1
        this.stepOnePage = "/pc/store/stepOneView" //回显
        this.sLogin = "/pc/sLogin"; //登录
        this.sRegister = "/pc/sRegister"; //注册/pc/uploadimage64
        this.sForget = "/pc/sForget"; //忘记密码
        this.stepTwo = "/pc/store/stepTwo"; //2/pc/store/stepTwoPage
        this.stepTwoPage = "/pc/store/stepTwoPage"; //2回显/pc/store/checkExamStatus
        this.checkExamStatus = "/pc/store/checkExamStatus"; //2回显/pc/store/stepThree
        this.stepThree = "/pc/store/stepThree"; //3/pc/store/stepThreePage
        this.stepThreePage = "/pc/store/stepThreePage"; //3回显
        this.getCheckIn = "/common/getCheckIn";
        this.refill = "/pc/store/refill"; //重新填写
        
        // this.delete = "/pc/cart/delete"; //购物车删除/pc/user/getUserInfo
        // this.getUserInfo = "/pc/user/getUserInfo"; //购物车删除

    

    }


               //重新填写
               REFILL(params) {
                return axios(this.refill, {
                    requireHead: true,
                    method: 'get',
                    params,
                });
            }
        //入住指引
        GETINFOZHIYING(params) {
            return axios(this.getCheckIn, {
                requireHead: true,
                method: 'get',
                params,
            });
        }
    //审核状态
    CHECKECAMSTATUS(params) {
        return axios(this.checkExamStatus, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //获取国家列表
    GET_COUNTRY(params) {
        return axios(this.getCountry, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //1
    SETONE(params) {
        return axios(this.stepOne, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //2
    STEPTWO(params) {
        return axios(this.stepTwo, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //2
    STEPTHREE(params) {
        return axios(this.stepThree, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //登录
    GET_SLOGIN(params) {
        return axios(this.sLogin, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //忘记密码
    SFORGET(params) {
        return axios(this.sForget, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //注册
    SREGISTER(params) {
        return axios(this.sRegister, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //info回显
    STEPONEPAGE(params) {
        return axios(this.stepOnePage, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //info回显2
    STEPTWOPAGE(params) {
        return axios(this.stepTwoPage, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //info回显3
    STEPTHREEPAGE(params) {
        return axios(this.stepThreePage, {
            requireHead: true,
            method: 'get',
            params,
        });
    }



    // GET_BANNERS(params) {
    //     return axios(this.banner, {
    //         requireHead: true,
    //         method: 'post',
    //         params
    //     });
    // }

};
export default new sell();