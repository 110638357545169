export default function({ $axios, req }) {
  $axios.onRequest(config => {
    if (process.server && req && req.headers.cookie) {
      const cookieparser = require('cookieparser');
      const parsedCookies = cookieparser.parse(req.headers.cookie);
      const myCookie = parsedCookies.myCookie || null;

      if (myCookie) {
        debugger
        config.headers.common['Authorization'] = `Bearer ${myCookie}`;
      }
    }
    return config;
  });
}
