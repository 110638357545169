

import { mapActions, mapState } from 'vuex'
export default {
  props: {
    address: Array,
    ad_active: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      visible: false, ad_active_por: this.ad_active,
      Applycode: "",
      plz: false,
      city: "",
      chectBol: true,
      city_name: "",
      post_code: "",
      address_id: ""
    }
  },
  computed: {},
  created () {
    // if (localStorage.getItem('Applycity')) {
    //   this.chectBol = false
    // }
  },
  mounted () {
    if (process.client) {
      this.Applycode = localStorage.getItem('Applycode') ? localStorage.getItem('Applycode') : ""
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
      }
    }
  },
  watch: {
    ad_active (val) {
      this.ad_active_por = val //
    }
  },
  methods: {
    ...mapActions('addressindex', ['SETADDRESSCODE']),
    // 
    Changeto () {
      this.chectBol = true
    },
    async codelist () {
      // var self = this
      // plz //显示zipcode错误提示
      //chectBol 显示输入的zipcode是否存在
      if (this.Applycode.length == 5) {
        var objInfo = {
          code: this.Applycode,
        }
        let { data } = await this.$api.auyFun.CODELIST(objInfo)
        if (data.data.length > 0) {
          this.chectBol = false
          this.city = data.data[0].city
          this.plz = false
          localStorage.setItem('Applycode', data.data[0].code)
          localStorage.setItem('Applycity', data.data[0].city)
          this.ad_active_por = 99
          this.cansel()
        } else {
          this.plz = true
        }
      } else {
        this.plz = true
      }

    },
    toaddress () {
      this.$customJump({
        path: '/address/2'
      })
    },
    check () {
      if (localStorage.getItem('Applycity')) {
        this.chectBol = false
        this.Applycode = localStorage.getItem('Applycode')
      }
      this.visible = !this.visible
    },
    close () {
      // this.$emit('upShow1')
    },
    toadSel (j, k) {
      this.ad_active_por = k
      this.$emit('upAddress', j.address_id)
      this.address_id = j.address_id
      this.chectBol = true
      this.plz = false
      this.Applycode = ""
      this.post_code = j.post_code
      this.city_name = j.city_name
      localStorage.setItem('Applycode', "")
      localStorage.setItem('Applycity', "")
    },

    async cansel () {
      let { data } = await auyFun.CANCELSELECT()

    },
    doneUp () {
      var _this = this
      _this.check()
      if (localStorage.getItem('Applycode')) {
        _this.$emit('upShow', localStorage.getItem('Applycode'), localStorage.getItem('Applycity'))
        var obj = {
          Applycode: localStorage.getItem('Applycode'),
          Applycity: localStorage.getItem('Applycity'),
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      } else {
        _this.$emit('upShow', _this.post_code, _this.city_name)
        var obj = {
          Applycode: _this.post_code,
          Applycity: _this.city_name,
          address_id: _this.address_id
        }
        _this.SETADDRESSCODE(obj)
      }

      // this.visible = false
    }
  },
  components: {}
}
