import Vue from "vue";
// function handlerError(error, vm, hook) {
//   console.log(error);
//   vm &&
//     vm.$handleError({
//       message: error.message,
//       name: error.name,
//       stack: error.stack,
//       hook
//     });
//   return true;
// }
function formatText(input) {
  // 去除符号和空格，只保留字母、数字和空格
  let cleanText = input.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');
  // 将空格替换为短线 -
  let formattedText = cleanText.replace(/\s+/g, '-');
  return formattedText;
}
// Vue.config.errorHandler = handlerError;

// Vue.prototype.$throw = handlerError;

Vue.filter("formatSrc", function(src) {
  if (!src) {
    return "";
  }
  return process.env.IMG + src.split(",")[0];
});
Vue.filter("getBuyNum", function(arr) {
  if (!Array.isArray(arr)) {
    return 0;
  }
  return arr.reduce((a, c) => a + c.buy_num, 0);
});

// Vue.filter("goodsUrl", function(id) {
//   if (!id) {
//     return "/";
//   }
//   return "/goods/" + id;
// });

Vue.filter("collect24", function(name) {
  if (name==0) {
    return "/categories/express-shipping-1";
  }else if(name==1){
    return "/categories/all-products-0";
  }
  // let formattedOutput = formatText(name)
 
  // +"-"+name.goods_id
});
Vue.filter("goodsnameUrl", function(name) {
  if (!name) {
    return "/";
  }
  let formattedOutput = formatText(name)
  return "/products/" + formattedOutput;
  // +"-"+name.goods_id
});
Vue.filter("collectionsnameUrl", function(name) {
  if (!name) {
    return "/";
  }
  let formattedOutput = formatText(name)
  return "/categories/" + formattedOutput;
  // +"-"+name.goods_id
});

Vue.filter("storeUrl", function(id) {
  if (!id) {
    return "/";
  }
  return "/store/" + id;
});
const floatNumRegxep = /\./g;
Vue.filter("cartPrice", function(num, sellPrice, discountPrice) {
  if (discountPrice) {
    let price = num * sellPrice;
    return floatNumRegxep.test(price) ? price.toFixed(2) : price;
  }
  let price = num * sellPrice;
  return floatNumRegxep.test(price) ? price.toFixed(2) : price;
});
