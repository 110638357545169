import axios from 'axios';
import qs from 'qs'
import Vue from "vue";
import cookies from '@/utils/cookie'
import CONFIGS from '/config'
const cookieparser = require( 'cookieparser' )

axios.defaults.baseURL=process.env.NODE_ENV == 'production'?"https://appen.meccle.com/v3/":"https://testen.bulkbuyworld.com/v3/"
// axios.defaults.baseURL="https://appen.meccle.com/v3/"
class auyFun {
    constructor(req) {
        this.getHistoryList = '/pc/getHistoryList'; //获取历史记录
        this.addCart = "/pc/cart/add" //添加购物车
        this.relatedGoods = "/pc/goods/relatedGoods"; //推荐商品/pc/cart/list
        this.cartList = "/pc/cart/listV3_2"; //购物车列表/pc/cart/edit
        this.upEdit = "/pc/cart/edit"; //购物车数量修改/pc/cart/delete
        this.delete = "/pc/cart/delete"; //购物车删除/pc/user/getUserInfo
        this.getUserInfo = "/pc/user/getUserInfo"; //购物车删除
        this.uploadimage64 = "/pc/uploadimage64"; //图片上传/pc/sendCodeSms
        this.sendCodeSms = "/pc/sendCodeSms"; //发送验证码/pc/checkCode
        this.sendCodeEmail = "/common/sendCodeEmail"; //发送邮箱验证码/pc/checkCode
        this.checkCode = "/pc/checkCode"; //确认发送验证码/pc/getRegionList
        this.getRegionList = "/pc/getRegionList"; //省市区
        this.test1 = "/pc/test1"; //省市区/pc/createVerifyCode
        this.createVerifyCode = "/pc/createVerifyCode"; //获取图形验证码/pc/getCommentImgList
        this.getCommentImgList = "/pc/getCommentImgList"; //商品评论图片列表/pc/addCommentHelp用户添加评论帮助标记
        this.addCommentHelp = "/pc/addCommentHelp"; //列表用户添加评论帮助标记/pc/getShipFee
        this.getShipFee = "/pc/getShipFee"; //计算运费/pc/adGoods
        this.adGoods = "/pc/adGoods"; //广告商品/pc/coupon/getList
        this.getList = "/pc/coupon/getList"; //优惠券列表/pc/collect/add
        this.storeadd = "/pc/collect/add"; //店铺收藏
        this.storecancel = "/pc/collect/cancel"; //取消店铺收藏/pc/collect/lists
        this.alllists = "/pc/collect/lists"; //收藏店铺列表/pc/coupon/myList
        this.cupmyList = "/pc/coupon/myList"; //我的优惠券/common/getConfig
        this.getConfigAb = "/common/getConfig"; //guanyuwomen1/pc/keywordsList
        this.keywordsList = "/pc/keywordsList"; //自动补全关键词列表/pc/googleBindAccount
        this.googleBindAccount = "/pc/googleBindAccount"; //自动补全关键词列表/pc/order/judgeGoodsStock
        this.judgeGoodsStock = "/pc/order/judgeGoodsStock"; //购买前判断库存/pc/otherLoginSwitch
        this.otherLoginSwitch = "/pc/otherLoginSwitch"; //第三方登录/pc/factory/getList
        this.getfacList = "/pc/factory/getListPc"; //厂商列表/pc/goods/getSKU
        this.getSKU = "/pc/goods/getSKU"; //厂商列表/pc/store/refill
        this.biddingGoods = "/pc/goods/biddingGoods"; //厂商列表/pc/store/refill
        this.GetConfigList = "common/getConfigList"; // footer 二维码/pc/collectGoods/getList
        this.collgetList = "/pc/subscribe/getList"; // footer 收藏商品列表/pc/collectGoods/cancel
        this.shopcancel = "/pc/collectGoods/cancel"; // footer 取消收藏商品列表/pc/collect/cancel/pc/collectGoods/add
        this.itemcancel = "/pc/subscribe/del"; // footer 取消收藏商品列表/pc/collect/cancel/pc/collectGoods/add
        this.shopFavorite = "/pc/collectGoods/add"; // footer 收藏商品/pc/collect/cancelhttps://testen.bulkbuyworld.com/v3/pc/address/stateList
        this.stateList = "/pc/address/stateList"; // footer 收藏商品/pc/order/confirmOrder
        this.confirmOrder = "/pc/order/confirmOrder"; // footer 收藏商品
        this.createOrder = "/pc/order/createOrder"; // footer 收藏商品/pc/order/createOrder/pc/order/cartConfirmOrder
        this.cartConfirmOrder = "/pc/order/cartConfirmOrder"; // footer 收藏商品/pc/order/createOrder/pc/order/cartCreateOrder
        this.cartCreateOrder = "/pc/order/cartCreateOrder"; // footer 收藏商品/pc/order/createOrder/pc/order/updatePayFee
        this.updatePayFee = "/pc/order/updatePayFee"; // footer 收藏商品/pc/order/createOrder/pc/order/pay
        this.newPay = "/pc/order/pay"; // footer 收藏商品/pc/order/createOrder
        this.searchOrder = "/pc/order/searchOrder" //支付结果查询
        this.countryList = "pc/countryList"  // 全球国家列表
        this.createUser = "pc/zaiPay/createUser" // 创建宅支付
        this.createCardAccounts = "pc/zaiPay/createCardAccounts"
        this.creditCardList = "pc/zaiPay/creditCardList"
        this.getQiuniuToken = "/common/getQiuniuToken"; //分类轮播/shopCn/suppliers/entry
        this.agentList = "/pc/store/agentList"
        this.collageGoodsList = "/pc/goods/collageGoodsList"//凑单商品列表
        this.getTipMsg = "/pc/goods/getTipMsg"//凑单商品列表提示语/pc/getCountryCode
        this.getCountryCode = "/pc/getCountryCode"//凑单商品列表提示语/pc/shop/storeTheme
        this.storeTheme = "/pc/shop/storeTheme"//店铺装修1/pc/address/codeList
        this.codeList = "/pc/address/codeList"//邮政编码检索/pc/address/defaultAddress
        this.defaultAddress = "/pc/address/defaultAddress"//邮政编码检索/pc/shop/getStoreThemeType
        this.getStoreThemeType = "/pc/shop/getStoreThemeType"//店铺魔板类型/pc/address/cancelSelect
        this.cancelSelect = "/pc/address/cancelSelect"//店铺魔板类型/pc/goods/arrivalReminder
        this.arrivalReminder = "/pc/goods/arrivalReminder"//到货提醒/pc/address/setSelectAddress设置选中地址
        this.setSelectAddress = "/pc/address/setSelectAddress"//到货提醒设置选中地址
        this.topFactory = "/pc/factory/topFactory"//到货提醒设置选中地址/v3/pc/factory/getList
        this.topFactorygetList = "/pc/factory/getList"//到货提醒设置选中地址/v3/pc/factory/getCategory
        this.getCategory = "/pc/factory/getCategory"//到货提醒设置选中地址/v3/pc/factory/getFactoryGoods
        this.getFactoryGoods = "/pc/factory/getFactoryGoods"//到货提醒设置选中地址//v3/pc/delSearchLog
        this.delSearchLog = "/pc/delSearchLog"//到货提醒设置选中地址/
        this.businessMember = 'pc/user/businessMember'  // 商家会员信息
        this.indexGoodsList = '/pc/indexGoodsList'  // 首页无限滚动/v3/pc/goods/esGetList
        this.esGetList = '/pc/goods/esGetList'  // 首页无限滚动/v3/share/statistics
        this.sharestatistics = '/pc/share/statistics'  // 首页无限滚动/v3/pc/share/exchange
        this.exchange = '/pc/share/exchange'  // 首页无限滚动/v3
        this.logList = '/pc/share/logList'  // 首页无限滚动/v3
        this.mergeOrderList = '/pc/order/mergeOrderList'  // 首页无限滚动/v3/pc/factory/topFactoryGoods
        this.topFactoryGoods = '/pc/factory/topFactoryGoods'  // 首页无限滚动/v3/pc/factory/topFactoryGoods
        this.moreOrderRefund = '/pc/order/moreOrderRefund'  // 订单批量退款pc/user/getInvitList
        this.getInvitList = '/pc/user/getInvitList'  //  邀请记录接/pc/coupon/receiveNew
        this.receiveNew = '/pc/coupon/receiveNew'  //  邀请记录接/pc/zaiPay/getZaiInfo
        this.getZaiInfo = '/pc/zaiPay/getZaiInfo'  //  邀请记录接/pc/prize/info
        this.dzpinfo = '/pc/prize/info'  //  邀请记录接/pc/prize/draw
        this.draw = '/pc/prize/draw'  //  邀请记录接
        this.ponitgetList = '/pc/accountLog/getList'  //  积分记录
        this.autoExpire = '/pc/updateMeccleGifts'  //  首页倒计时再查询
        this.invtRecord = '/pc/prize/invtRecord'  //  邀请转盘列表
        this.prizeRecord = '/pc/prize/prizeRecord'  //  邀请转盘列表/pc/account
        this.pcaccount = '/pc/account'  //  邀请转盘列表
        this.hiddenList = '/pc/hiddenList'  //  影藏评论/pc/user/deleteUser
        this.deleteUser = '/pc/user/deleteUser'  //  注销pc/bindMobile
        this.bindMobile = '/pc/bindMobile'  //  注销pc/accountLog/invitePointLog
        this.invitePointLog = '/pc/accountLog/invitePointLog'  //  注销
        this.associationSuggestionViewService = '/search/associationSuggestionViewService'
        this.getUserUUID = '/pc/user/getUserUUID'
        this.addAction = '/pc/addAction'
        this.indexNotice = '/pc/indexNotice'
        this.byCartCouponList = '/pc/cart/byCartCouponList'
        this.goodsInquiry = '/pc/goods/goodsInquiry' //大件商品提交信息
        // mailchimp
        this.mailchimpAdd = '/pc/mailchimp/addCart' //添加购物车
        this.mailchimpUser = '/pc/mailchimp/addCustomer' //添加用户
        this.mailchimpEdit = '/pc/mailchimp/editCart' //编辑购物车
        this.mailchimpDel = '/pc/mailchimp/deleteCart' //删除购物车
        this.giftGoodsList = '/pc/goods/giftGoodsList' //购物车礼品
        this.undeliveredLog = '/pc/order/undeliveredLog' //购物车礼品
        this.indexGiftPop = '/pc/indexGiftPop' //购物车礼品
        this.statistics = '/pc/balance/statistics' //购物车礼品
        this.subscribeADD = '/pc/subscribe/add' //购物车礼品
        this.subscribecancel = '/pc/subscribe/cancel' //购物车礼品
        this.createSignature = '/pc/createSignature' //购物车礼品
        
        this.adlistnew="/pc/adList"
        this.indexhome="/pc/indexV3_2"
        this.goodsdetail="/pc/goods/show"
        this.sidebar="/pc/sidebar"
        this.receive = '/pc/coupon/receive' // 领取优惠券/pc/coupon/orderCouponList
        this.topCategoryShow="/pc/topCategoryShow"
        
        
  
      
        axios.interceptors.request.use((config) => {
            if (config.url == "/search/associationSuggestionViewService") {
                config.baseURL = "https://es.meccle.com"
              }
            config.headers['platformType'] = 3
            if(process.client){
                config.headers['uuid'] =localStorage.getItem("newuuid")
                if(localStorage.getItem('userInfo')){
                }else{
                    const userInfo = cookies.get("userInfo")
                    if(userInfo){
                        localStorage.setItem("userInfo",userInfo)
                    }
                }
            }
         
            const token = cookies.get("myCookie")
            token && (config.headers['token'] = token)
           
    
          
            // 判断当前是否在服务端
            // if (process.server) {
            //     console.log(req.headers.cookie,"cooollllllll")
            //   // 服务端从请求头中获取cookie
            //   if (req && req.headers.cookie) {
            //     const parsedCookies = cookieparser.parse(req.headers.cookie);
            //     myCookie = parsedCookies.myCookie || null;
            //   }
            // } else if (process.client) {
            //   // 客户端从浏览器获取cookie
            //   myCookie = cookies.get('myCookie');
            // }
            // myCookie && (config.headers['token'] = myCookie)
            // console.log("home axios" , this.route.query.lang)
            // config.baseURL = process.env
            // config.headers.platformType = localStorage.getItem("app_type")
            // if (localStorage.getItem('typeApp')) {
            //     if (localStorage.getItem('lang') == "en") {
            //         config.headers.lang = "en-us"
            //     } else {
            //         config.headers.lang = "zh-cn"
            //     }
            // } else {
            //     if (config.headers.lang == "en-us" || !config.headers.lang) {
            //         config.headers.lang = "en-us"
            //     } else {
            //         config.headers.lang = "zh-cn"
            //     }
            // }
            // config.headers.token = localStorage.getItem('token')
            // config.headers.storetoken = localStorage.getItem("businessToken")
            // if (store.state.business.businessToken) {
            //     config.headers.storetoken = store.state.business.businessToken
            // }
            return config
        }, (error) => {
            return Promise.reject(error)
        })
        axios.interceptors.response.use(
            (response) => {
              const res = response.data || response.body;
              if (res.code == 401) {
                if(process.client){
                let uuid= localStorage.getItem('newuuid')
                sessionStorage.setItem('coupon_bol', 2)
                localStorage.clear()
                sessionStorage.clear()
               localStorage.setItem('newuuid',uuid)
                // Vue.$store.commit("onTab", "")
                // Vue.prototype.$message({
                //   message: res.msg,
                //   type: 'warning',
                // })
                // window.location.href = process.env.link_URL+"/content/login";
                return
              }
            }
              // lastRequestConfig = null;
              // cancelLastRequest = null;
              return response;
            },
            (error) => {
              // lastRequestConfig = null;
              // cancelLastRequest = null;
              return Promise.reject(error);
            }
          );
        
        
        
    }
    
    TOPCATTIT(params) {
        return axios(this.topCategoryShow, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    SIDEBAR(params) {
        return axios(this.sidebar, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    GOODSDETAIL(params) {
        return axios(this.goodsdetail, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INDEXHOME(params) {
        return axios(this.indexhome, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    ADLISTNEW(params) {
        return axios(this.adlistnew, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    CEEATESIGNA(params) {
        return axios(this.createSignature, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    STATIS(params) {
        return axios(this.statistics, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INDEXGIFTPOP(params) {
        return axios(this.indexGiftPop, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
        RECETIVE(params) {
            return axios(this.receive, {
                requireHead: true,
                method: 'post',
                data: qs.stringify(params),
            })
        }
        SUBSCRCANSEL(params) {
            return axios(this.subscribecancel, {
                requireHead: true,
                method: 'post',
                data: qs.stringify(params)
            });
        }
        SUBSCRADD(params) {
            return axios(this.subscribeADD, {
                requireHead: true,
                method: 'post',
                data: qs.stringify(params)
            });
        }
    UNDELILOG(params) {
        return axios(this.undeliveredLog, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    GIFTLIST(params) {
        return axios(this.giftGoodsList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    // mailchimp
   MAILCHIMPADD(params) {
        return axios(this.mailchimpAdd, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    MAILCHIMPUSER(params) {
        return axios(this.mailchimpUser, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    MAILCHIMPEDIT(params) {
        return axios(this.mailchimpEdit, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    MAILCHIMPDEL(params) {
        return axios(this.mailchimpDel, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
 // mailchimp


    GOODSINQUIRY(params) {
        return axios(this.goodsInquiry, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    BYCARTCOUPONLIST(params) {
        return axios(this.byCartCouponList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INDEXNOTIC(params) {
        return axios(this.indexNotice, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    ADDACTION(params) {
        return axios(this.addAction, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    GETUSERUUID(params) {
        return axios(this.getUserUUID, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    //联想搜索词
    ALIBABA(params) {
        return axios(this.associationSuggestionViewService, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INVITEPOINT(params) {
        return axios(this.invitePointLog, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    BINDMOBILE(params) {
        return axios(this.bindMobile, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    DELETEUSER(params) {
        return axios(this.deleteUser, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    HIDDENLIST(params) {
        return axios(this.hiddenList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    PCACOUNT(params) {
        return axios(this.pcaccount, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    PRIZERECORD(params) {
        return axios(this.prizeRecord, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INvtreaord(params) {
        return axios(this.invtRecord, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    AUTOEXP(params) {
        return axios(this.autoExpire, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    POINTLIST(params) {
        return axios(this.ponitgetList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    DZPDRAW(params) {
        return axios(this.draw, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    DZPINFO(params) {
        return axios(this.dzpinfo, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    GETZAIINFO(params) {
        return axios(this.getZaiInfo, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    RECEIVENEW(params) {
        return axios(this.receiveNew, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    GETINVITLIST(params) {
        return axios(this.getInvitList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    MOREORDERRE(params) {
        return axios(this.moreOrderRefund, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    TOPFACTORYGOODS(params) {
        return axios(this.topFactoryGoods, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    MERGEORDER(params) {
        return axios(this.mergeOrderList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    LOGLIST(params) {
        return axios(this.logList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    ECCHANGE(params) {
        return axios(this.exchange, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    STATISTICS(params) {
        return axios(this.sharestatistics, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    ESGETLIST(params) {
        return axios(this.esGetList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    INDEXGOODSLIST(params) {
        return axios(this.indexGoodsList, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    BUSINESSMEMBER(params) {
        return axios(this.businessMember, {
        requireHead: true,
        method: 'get',
        params,
        });
        }


    DELSEACHLOG(params) {
        return axios(this.delSearchLog, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    GETFACTGOODS(params) {
        return axios(this.getFactoryGoods, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
    GETCATEGORY(params) {
        return axios(this.getCategory, {
        requireHead: true,
        method: 'get',
        params,
        });
        }
                TOPFACTORYLIST(params) {
                    return axios(this.topFactorygetList, {
                    requireHead: true,
                    method: 'get',
                    params,
                    });
                    }
                    TOPFACTORY(params) {
                        return axios(this.topFactory, {
                        requireHead: true,
                        method: 'get',
                        params,
                        });
                        }
                SETSELECTADDRESS(params) {
                    return axios(this.setSelectAddress, {
                        requireHead: true,
                        method: 'post',
                        data: qs.stringify(params)
                    });
                }
                ARRICALREM(params) {
                    return axios(this.arrivalReminder, {
                        requireHead: true,
                        method: 'post',
                        data: qs.stringify(params)
                    });
                }
                CANCELSELECT(params) {
                    return axios(this.cancelSelect, {
                        requireHead: true,
                        method: 'post',
                        data: qs.stringify(params)
                    });
                }
                //店铺魔板类型
                GETSTORETHEME(params) {
                return axios(this.getStoreThemeType, {
                requireHead: true,
                method: 'get',
                params,
                });
                }
                //邮政编码检索
                DEFAULTADDRESS(params) {
                return axios(this.defaultAddress, {
                requireHead: true,
                method: 'get',
                params,
                });
                }
                //邮政编码检索
                CODELIST(params) {
                return axios(this.codeList, {
                requireHead: true,
                method: 'get',
                params,
                });
                }
                //店铺装修1
                STORETHEME(params) {
                return axios(this.storeTheme, {
                requireHead: true,
                method: 'get',
                params,
                });
                }
        //凑单商品列表提示语
        GETCOUNTRYCODE(params) {
            return axios(this.getCountryCode, {
                requireHead: true,
                method: 'get',
                params,
            });
        }
    //凑单商品列表提示语
    GETTIPSMSG(params) {
        return axios(this.getTipMsg, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //凑单商品列表
    COLLAGEGOODS(params) {
        return axios(this.collageGoodsList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //支付结果查询
    AGENTLIST(params) {
        return axios(this.agentList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //koken图片
    GETQIAN(params) {
        return axios(this.getQiuniuToken, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    CREATEUER(params) {
        return axios(this.createUser, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    ACCOUNTS(params) {
        return axios(this.createCardAccounts, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //支付结果查询
    SENDCODEEMAILS(params) {
        return axios(this.searchOrder, {
            requireHead: true,
            method: 'get',
            params,
        });
    }

    CREDEIECARDLIST(params) {
        return axios(this.creditCardList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }

    COUNTRYLIST(params) {
        return axios(this.countryList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    NEWPAY(params) {
        return axios(this.newPay, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    UPDATAPAYFEE(params) {
        return axios(this.updatePayFee, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    CARTCREATEORDER(params) {
        return axios(this.cartCreateOrder, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //确认订单
    CARTCONFIRMORDER(params) {
        return axios(this.cartConfirmOrder, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //收藏商品
    CREATEORDER(params) {
        return axios(this.createOrder, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //确认订单
    CONFIRMORDER(params) {
        return axios(this.confirmOrder, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //州(省)列表
    STATELIST(params) {
        return axios(this.stateList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //收藏商品
    SHOPFAVORITE(params) {
        return axios(this.shopFavorite, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //取消收藏商品列表
    SHOPCANCEL(params) {
        return axios(this.shopcancel, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
        //取消收藏商品列表
        ITEMCANCEL(params) {
            return axios(this.itemcancel, {
                requireHead: true,
                method: 'post',
                data: qs.stringify(params)
            });
        }
    
    //厂商列表
    COLLGETLIST(params) {
        return axios(this.collgetList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //谷歌绑定
    GETCONFIG(params) {
        return axios(this.GetConfigList, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //厂商列表
    BIDDING(params) {
        return axios(this.biddingGoods, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //谷歌绑定
    GETSKU(params) {
        return axios(this.getSKU, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //厂商列表
    GETFACLIST(params) {
        return axios(this.getfacList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //第三方登录
    OTHERLOGIN(params) {
        return axios(this.otherLoginSwitch, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //购买前判断库存
    JUDGEGOODS(params) {
        return axios(this.judgeGoodsStock, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //谷歌绑定
    GOOGBIND(params) {
        return axios(this.googleBindAccount, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //自动补全关键词列表
    KEYWORDS(params) {
        return axios(this.keywordsList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //关于我们
    GETCOFIGAB(params) {
        return axios(this.getConfigAb, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //优惠券列表
    CUPMYLIST(params,headers) {
        return axios(this.cupmyList, {
            requireHead: true,
            method: 'get',
            params,
            headers

        });
    }
    //优惠券列表
    ALLLISTS(params) {
        return axios(this.alllists, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //取消店铺收藏
    STORECANCEL(params) {
        return axios(this.storecancel, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //店铺收藏
    STOREADD(params) {
        return axios(this.storeadd, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //优惠券列表
    GETCUPLIST(params) {
        return axios(this.getList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //广告商品
    ADGOODS(params) {
        return axios(this.adGoods, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //计算运费
    GETSHIPFEE(params) {
        return axios(this.getShipFee, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //商品评论图片列表
    ADDCOMMENT(params) {
        return axios(this.addCommentHelp, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //商品评论图片列表
    GETCOMMRNTIMG(params) {
        return axios(this.getCommentImgList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //获取历史记录
    TEST1(params) {
        return axios(this.test1, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //获取图形验证码
    CREATECODE(params) {
        return axios(this.createVerifyCode, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //获取历史记录
    GET_HISTORY(params) {
        return axios(this.getHistoryList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }

    //发送验证码
    SENDCODEEMAIL(params) {
        return axios(this.sendCodeEmail, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //发送验证码
    SENDCODESMS(params) {
        return axios(this.sendCodeSms, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //确认发送验证码
    CHECKCODE(params) {
        return axios(this.checkCode, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //省市区
    GETREGIONLIST(params) {
        return axios(this.getRegionList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //推荐商品
    GET_RELATEDGOODS(params) {
        return axios(this.relatedGoods, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //添加购物车
    GET_ADDCART(params) {
        return axios(this.addCart, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //购物车列表
    GET_CARTLIST(params) {
        return axios(this.cartList, {
            requireHead: true,
            method: 'get',
            params,
        });
    }
    //购物车删除
    UP_DETAIL(params) {
        return axios(this.delete, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //购物车数量
    UP_EDIT(params) {
        return axios(this.upEdit, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //图片上传
    UPLOADIMG(params) {
        return axios(this.uploadimage64, {
            requireHead: true,
            method: 'post',
            data: qs.stringify(params)
        });
    }
    //获取用户信息
    GET_USERINFO(params) {
        return axios(this.getUserInfo, {
            requireHead: true,
            method: 'get',
            params,
        });
    }


    // GET_BANNERS(params) {
    //     return axios(this.banner, {
    //         requireHead: true,
    //         method: 'post',
    //         params
    //     });
    // }

};
export default new auyFun();